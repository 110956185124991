<template>
  <div class="home">
    <!-- <Header/> -->
    <input type="text" v-model="tempPIN" placeholder="Enter PIN generated in web app">
    <input type="text" v-model="tempId" placeholder="Device Id to simulate">
    <input type="text" v-model="signalRSessionId" placeholder="Explicit Session ID to try to connect to (if blank, the server rties to connect to the user's CurrentSessionId)">
    <button @click="devicelogin">Device login</button>   
    <button @click="getCurrentSession">Get current session</button>  
    <button @click="getCurrentSessionSignalR">Get current session SignalR</button>  
    <button @click="getBroadcasts">Get broadcasts</button>  
    <br>
    <input type="number" v-model="tempBattery" placeholder="Enter battery value">    
    <input type="number" v-model="tempProgress" placeholder="Enter playback progress">
    <input type="number" v-model="tempContentId" placeholder="Enter contentId">
    <input type="number" v-model="tempContentProgress" placeholder="Enter contentProgress">
    <input type="checkbox" name="onhead" id="onhead" v-model="tempOnHead">
    <label for="onhead" style="float:none;">OnHead</label>
    <button @click="sendStatus">Send status</button>    
    <div>
      {{tempMessage}}
    </div>
    <textarea v-model="tempAnswer"></textarea>
    <button @click="sendAnswer">Send answer(s)</button>  
    <br> 
    <button @click="unlinkDeviceThroughSignalR">Unlink device through SignalR Hub</button> (aka unlink + abort connection)
    <br>
    <input type="text" v-model="metadataKey" placeholder="Metadata Key">
    <input type="text" v-model="metadataValue" placeholder="Metadata Value">
    <button @click="setMetadata">Set metadata</button>  
    <br>
    <textarea v-model="tempFileIntegrity"></textarea>
    <button @click="sendFileIntegrity">Send file integrity data</button>  
    <br>
    <input type="text" v-model="testSessionId">
    <button @click="joinSession">Join session</button>  
  </div>
</template>

<script>
// @ is an alias to /src
//import Header from '@/components/Header.vue'
import { HubConnectionBuilder } from '@microsoft/signalr'
import axios from 'axios'

export default {
  name: 'DeviceSimulator',
  components: {
    // Header
  },
  data: function(){
    return{
      connection: undefined,
      signalRSessionId: undefined,
      tempPIN: undefined,
      tempId: undefined,
      tempMessage: undefined,
      testSessionId: undefined,
      tempDeviceToken: undefined,
      tempBattery: undefined,
      metadataKey: undefined,
      metadataValue: undefined,
      tempFileIntegrity: JSON.stringify([{
        eTag: '234523562344562',
        size: 123
      },{
        eTag: '666666666',
        size: 12345
      }]),
      tempOnHead: false,
      tempProgress: undefined,
      tempContentProgress: undefined,
      tempContentId: undefined,
      tempAnswer: JSON.stringify([{
        questionId: 1,
        optionId: 1,
        segmentId: 123,
        value: null,
        hotspot: null
      }])
    }
  },
  methods:{
    async devicelogin(){
        let resp = await axios({ url: "auth/devicelogin", data: {deviceId: this.tempId, pin: this.tempPIN }, method: "POST" })
        console.log("got device token: " + resp.data)
        let this2=this;

        let temptokenvar = resp.data.token
        this.tempDeviceToken = temptokenvar
        this.connection = new HubConnectionBuilder()
          .withUrl(process.env.VUE_APP_API_URL + 'devicehub' + (this.signalRSessionId ? '?sessionId=' + this.signalRSessionId : ''),{
              accessTokenFactory: () => temptokenvar
          })
          //.configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build();    
        this.connection.start()
          // .done(function(){ console.log('SignalR connected, connection ID=' + this.connection.id); })
          // .fail(function(){ console.log('Could not connect to SignalR'); });   // TODO similar without jQuery 
        this.connection.on("Message", function (message) {
          console.log(message);
          this2.tempMessage = message;
        });      
        this.connection.on("PlaySegment", function (request) {
          console.log(request)
          console.log("Playing segment with Id: " + request.id + " and (temporary) filename used if any: " + request.file + " (clock: " + request.clock + ")")
          this2.tempMessage = "Playing segment with Id: " + request.id + " and (temporary) filename used if any: " + request.file + " (clock: " + request.clock + ")"
        });    
        this.connection.on("ExitSegment", function (req) {
          console.log("Exited segment" + " (clock: " + req.clock + ")")
          this2.tempMessage = "Exited segment" + " (clock: " + req.clock + ")"
        });   
        this.connection.on("PauseSession", function (req) {
          console.log("Session paused: " + req.message + " (clock: " + req.clock + ")")
          this2.tempMessage = "Session paused: " + req.message  + " (clock: " + req.clock + ")"
        });   
        this.connection.on("ResumeSession", function (req) {
          console.log("Session resumed" + " (clock: " + req.clock + ")")
          this2.tempMessage = "Session resumed"  + " (clock: " + req.clock + ")"
        }); 
        this.connection.on("SelfPacedOn", function (req) {
          console.log("Self-paced mode ON" + " (clock: " + req.clock + ")")
          this2.tempMessage = "Self-paced mode ON" + " (clock: " + req.clock + ")"
        }); 
        this.connection.on("SelfPacedOff", function (req) {
          console.log("Self-paced mode OFF" + " (clock: " + req.clock + ")")
          this2.tempMessage = "Self-paced mode OFF" + " (clock: " + req.clock + ")"
        }); 
        this.connection.on("DeviceUnlinked", function (id) {
          console.log(`This device with id ${id} has been unlinked from the user`)
          this2.tempMessage = `This device with id ${id} has been unlinked from the user`
        }); 
        this.connection.on("DeviceDisconnected", function (id) {
          console.log("Device disconnected with id: " + id)
          this2.tempMessage = "Device disconnected with id: " + id
        }); 
        this.connection.on("SessionBroadcast", function (req) {
          console.log(`Broadcast: id ${req.id}, clock ${req.clock}, status ${req.status}`)
          //this2.tempMessage = "Broadcast: " + req
        });         
    },
    async sendFileIntegrity(){
      await axios({ url: "mdm/fileintegrity", method: 'POST', data: this.tempFileIntegrity ? JSON.parse(this.tempFileIntegrity) : null, headers:{ Authorization: "Bearer " + this.tempDeviceToken, 'Content-Type': "application/json"} })
    },
    async getCurrentSession(){
      let resp = await axios({ url: "sessions/current", headers:{ Authorization: "Bearer " + this.tempDeviceToken} })
      console.log("got current session:")
      console.log(resp.data)
      this.testSessionId = resp.data.session?.id;
    },
    async joinSession(){
      let resp = await axios({ url: "sessions/current?id="+this.testSessionId, headers:{ Authorization: "Bearer " + this.tempDeviceToken}, method: 'POST' })
      console.log("set current session:")
      console.log(resp.data)
      this.testSessionId = resp.data.id
    },
    async getBroadcasts(){
      let resp = await axios({ url: "sessions/broadcasts", headers:{ Authorization: "Bearer " + this.tempDeviceToken} })
      console.log("got broadcasts:")
      console.log(resp.data)
    },
    async getCurrentSessionSignalR(){
      this.connection.invoke("GetCurrentSession")
      .then(function (ret) {
          console.log(ret)
      })
      .catch(function (err) {
        return console.error(err.toString());
      });
    },
    async sendStatus(){
      var wtf = {
        Dummy1: this.tempBattery, 
        Battery: Number(this.tempBattery), 
        Progress: Number(this.tempProgress),
        ContentId: Number(this.tempContentId),
        ContentProgress: Number(this.tempContentProgress),
        OnHead: this.tempOnHead
      };
      console.log(wtf);
      this.connection.invoke("DeviceStatus", wtf).catch(function (err) {
        return console.error(err.toString());
      });
    },
    async sendAnswer(){
      var wtf = JSON.parse(this.tempAnswer)
      console.log(wtf);
      this.connection.invoke("SubmitAnswer", wtf).catch(function (err) {
        return console.error(err.toString());
      });
    },
    async setMetadata(){
      var wtf = JSON.parse(`{"key": "${this.metadataKey}", "value": "${this.metadataValue}"}`)
      console.log(wtf);
      this.connection.invoke("SetMetadata", wtf).catch(function (err) {
        return console.error(err.toString());
      });
    },
    async unlinkDeviceThroughSignalR(){      
      this.connection.invoke("UnlinkDevice").catch(function (err) {
        return console.error(err.toString());
      });
    }
  },
  created(){
  }
}
</script>
